import ahlzahir from "./logos-coles/ahlzahir.png";
import aitana from "./logos-coles/aitana.png";
import aldeafuente from "./logos-coles/aldeafuente.png";
import aldovea from "./logos-coles/aldovea.png";
import altozano from "./logos-coles/altozano.png";
// import canigo from "./logos-coles/canigo.png";
import elencinar from "./logos-coles/el-encinar.png";
import elprado from "./logos-coles/el-prado.png";
import elvedat from "./logos-coles/el-vedat.png";
import entreolivos from "./logos-coles/entreolivos.png";
import fomento from "./logos-coles/fomento.png";
import fundacion from "./logos-coles/fundacion.jpeg";
// import lasacacias from "./logos-coles/las-acacias.png";
import lastablasvalverde from "./logos-coles/las-tablas-valverde.png";
import losolmos from "./logos-coles/los-olmos.png";
import losrobles from "./logos-coles/los-robles.png";
import miralvent from "./logos-coles/miralvent.png";
import miravalles from "./logos-coles/miravalles.png";
import monteagudo from "./logos-coles/monteagudo.png";
import montealto from "./logos-coles/montealto.png";
import montearagon from "./logos-coles/montearagon.png";
import montecastelo from "./logos-coles/montecastelo.png";
import montespino from "./logos-coles/montespino.png";
import nelva from "./logos-coles/nelva.png";
import penalba from "./logos-coles/penalba.png";
import penamayor from "./logos-coles/penamayor.png";
import penarredonda from "./logos-coles/penarredonda.png";
import pinoalbar from "./logos-coles/pinoalbar.png";
import sansuena from "./logos-coles/sansuena.png";
import tabladilla from "./logos-coles/tabladilla.png";
import torrenova from "./logos-coles/torrenova.png";
import torrevelopenalabra from "./logos-coles/torrevelo-penalabra.png";
import vilavella from "./logos-coles/vilavella.png";

const Fomento = () => {
  return (
    <div className="fomento">
      <style type="text/css">
        {`
body {
margin: 0;
padding: 0;
font-family: Avenir,Helvetica,Arial,sans-serif;
color: #2c3e50;
background-color: #fff;
float: left
}

h1 {
text-align: center;
font-size: 38px;
margin: 10px 0;
}


.num {
display: inline-block;
width:50px;
height: 50px;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;
background-color:#a5c2c6;
line-height: 50px;
text-align: center;
margin-right: 20px;
}


.fomento-logo {display: block; width:100%; float: left; text-align: center; margin-top: 15px;}
.eel-title {display: block; width:100%; float: left; text-align: center;}
.first-step {display: block; width:100%; float: left; text-align: center;}
.first-step p {font-size: 18px; margin: 0;}
.schools {display: block; width:80%; float: left; margin: 40px 10%;}
.school {display: inline-block; width:20%; float: left; text-align: center;}
.school a {
display: block;
text-decoration: none;
color: #727272;
font-size: 12px;
-webkit-border-radius: 30px;
-moz-border-radius: 30px;
border-radius: 30px;
padding: 10px 0 5px 0;
}
.school a:hover {background-color: #c7dfe2}
.help {display: block;float: left;width: 100%; margin: 0 0 20px 0 ; text-align: center;}
`}
      </style>
      <div className="fomento-logo">
        <img src={fomento} alt="Fomento logo" height="90" width="" />
      </div>

      <div className="eel-title">
        <h1>English Exams Lab</h1>
      </div>

      <div className="first-step">
        <p>
          <span className="num">1</span> Por favor, seleccione el centro al que pertenece el/la alumno/a:
        </p>
      </div>

      <div className="schools">
        <div className="school">
          <a href="/userdata?school=ahlzahir">
            <img src={ahlzahir} alt="ahlzahir" />
            <h2>Ahlzahir</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=aitana">
            <img src={aitana} alt="aitana" />
            <h2>Aitana</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=aldeafuente">
            <img src={aldeafuente} alt="aldeafuente" />
            <h2>Aldeafuente</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=aldovea">
            <img src={aldovea} alt="aldovea" />
            <h2>Aldovea</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=altozano">
            <img src={altozano} alt="altozano" />
            <h2>Altozano</h2>
          </a>
        </div>
        {/* <div className="school">
          <a href="/userdata?school=canigo">
            <img src={canigo} alt="canigo" />
            <h2>Canigo</h2>
          </a>
        </div> */}
        <div className="school">
          <a href="/userdata?school=el-encinar">
            <img src={elencinar} alt="el encinar" />
            <h2>El Encinar</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=el-prado">
            <img src={elprado} alt="elprado" height="44" />
            <h2>El Prado</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=redin">
            <img src={miravalles} alt="redin" />
            <h2>El Redín</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=el-vedat">
            <img src={elvedat} alt="elvedat" />
            <h2>El Vedat</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=entreolivos">
            <img src={entreolivos} alt="entreolivos" />
            <h2>Entreolivos</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=fomento">
            <img src={fundacion} alt="fomento" />
            <h2>Fomento fundación</h2>
          </a>
        </div>
        {/* <div className="school">
          <a href="/userdata?school=las-acacias">
            <img src={lasacacias} alt="las-acacias" />
            <h2>Las Acacias</h2>
          </a>
        </div> */}
        <div className="school">
          <a href="/userdata?school=las-tablas">
            <img src={lastablasvalverde} alt="las-tablas" />
            <h2>Las Tablas</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=los-olmos">
            <img src={losolmos} alt="los-olmos" />
            <h2>Los Olmos</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=los-robles">
            <img src={losrobles} alt="los-robles" />
            <h2>Los Robles</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=miralvent">
            <img src={miralvent} alt="miralvent" />
            <h2>Miralvent</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=miravalles">
            <img src={miravalles} alt="miravalles" />
            <h2>Miravalles</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=monteagudo">
            <img src={monteagudo} alt="monteagudo" />
            <h2>Monteagudo</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=montealto">
            <img src={montealto} alt="montealto" />
            <h2>Montealto</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=montearagon">
            <img src={montearagon} alt="montearagon" />
            <h2>Montearagón</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=montecastelo">
            <img src={montecastelo} alt="montecastelo" />
            <h2>Montecastelo</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=montespino">
            <img src={montespino} alt="montespino" />
            <h2>Montespiño</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=nelva">
            <img src={nelva} alt="nelva" />
            <h2>Nelva</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=penalba">
            <img src={penalba} alt="penalba" />
            <h2>Peñalba</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=penamayor">
            <img src={penamayor} alt="penamayor" />
            <h2>Peñamayor</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=penarredonda">
            <img src={penarredonda} alt="penarredonda" />
            <h2>Peñarredonda</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=pinoalbar">
            <img src={pinoalbar} alt="pinoalbar" />
            <h2>Pinoalbar</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=sansuena">
            <img src={sansuena} alt="sansuena" />
            <h2>Sansueña</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=tabladilla">
            <img src={tabladilla} alt="tabladilla" />
            <h2>Tabladilla</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=torrenova">
            <img src={torrenova} alt="torrenova" />
            <h2>Torrenova</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=torrevelo-penalabra">
            <img src={torrevelopenalabra} alt="torrevelo-penalabra" />
            <h2>Torrevelo - Peñalabra</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=valverde">
            <img src={lastablasvalverde} alt="valverde" />
            <h2>Valverde</h2>
          </a>
        </div>
        <div className="school">
          <a href="/userdata?school=vilavella">
            <img src={vilavella} alt="vilavella" />
            <h2>Vilavella</h2>
          </a>
        </div>
      </div>

      <div className="help">
        <a href="mailto:support@englishexamslab.com">support@englishexamslab.com</a>
      </div>
    </div>
  );
};

export default Fomento;
