const Spinner = () => {
  return (
    <div className="spinner">
      <style type="text/css">
        {`
    .spinner { display: flex; justify-content: center; align-items: center; height: 100vh; } 
    .spinner::after { content: ""; width: 50px; height: 50px; border: 5px solid #ccc; border-top-color: #333; border-radius: 50%; animation: spin 1s linear infinite; } @keyframes spin { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }`}
      </style>
    </div>
  );
};

export default Spinner;
