import { loadStripe } from "@stripe/stripe-js";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Checkout from "./Checkout";
import FailedPayment from "./FailedPayment";
import SchoolSelection from "./SchoolSelection";
import SuccessfulPayment from "./SuccessfulPayment";
import UserData from "./UserData";

// const stripePromise = loadStripe("pk_test_51MbMOMAHcc1uLdKY1V8QyhIwK9fzMpyzHTkU65vy7oITNjblfxZCK6J5Qt3o5PTYI0Oip4H9e8KTNB920y5gpgZC00MZSZvBRP");
const stripePromise = loadStripe("pk_live_51NlXoVCX8hDXNtJuBJKQXNO0LmUfhBYQQP4UttGJOBV8uQZDpacHaaiynzkdJdui6ldgDyLjhHeefTpfklaim2hr00MNVNn3aK"); // Spanish entity
// const stripePromise = loadStripe("pk_live_51MbMOMAHcc1uLdKY52EVt55903BttDph2l9figeZ9jSu4Xlkggcm6g7VysMW9JMr9I0QQC7FxPL0RY4kJeNdPi2I00APrCb9c5"); // English entity

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SchoolSelection />} />
        <Route path="/userdata" element={<UserData />} />
        <Route path="/payment-success" element={<SuccessfulPayment />} />
        <Route path="/payment-failure" element={<FailedPayment />} />
        <Route path="/checkout" element={<Checkout stripePromise={stripePromise} />} />
        <Route path="*" element={<SchoolSelection />} />
      </Routes>
    </Router>
  );
}
