import err from "./imgs/err.png";
import img from "./imgs/img.png";
import fomento from "./logos-coles/fomento.png";

const Fomento = () => {
  return (
    <div className="fomento">
      <style type="text/css">
        {`
        body {
        margin: 0;
        padding: 0;
        font-family: Avenir,Helvetica,Arial,sans-serif;
        color: #2c3e50;
        background-color: #fff;
        float: left;
        width: 100%;
    }
    h1 {
        text-align: center;
        font-size: 38px;
        margin: 10px 0;
    }
    .num {
        display: inline-block;
        width:50px;
        height: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-color:#a5c2c6;
        line-height: 50px;
        text-align: center;
        margin-right: 20px;
    }
    .fomento-logo {display: block; width:100%; float: left; text-align: center; margin-top: 15px;}
    .eel-title {display: block; width:100%; float: left; text-align: center;}
    .first-step {display: block; width:100%; float: left; text-align: center;}
    .first-step p {font-size: 18px; margin: 0;}
    .schools {display: block; width:80%; float: left; margin: 40px 10%;}
    .school {display: inline-block; width:20%; float: left; text-align: center;}
    .school a {
        display: block;
        text-decoration: none;
        color: #727272;
        font-size: 12px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        padding: 10px 0 5px 0;
    }
    .school a:hover {background-color: #c7dfe2}
    .help {display: block;float: left;width: 100%; margin: 0 0 20px 0 ; text-align: center;}
    .actions {
        display: block; width:100%; float: left; text-align: center; margin-top: 15px;
    }
    .image {
        display: block; width: 45%; float: left; border-right: 1px solid #ccc; min-height: 500px; 
    }
    .image img {
        display: block;
        width:450px;
        background-color: blue;
        float: right;
        margin-right: 10%;

    }
    .steps {
        display: block; width: 48%; float: left; text-align: left; margin-left: 2%;
    }
    .steps p {font-size: 18px; margin: 0 0 10px 0;}
    .btn-comprar {
        width: ;
        padding: ;
        text-align: center;
        background-color: green;
        font-size: 20px;
        text-decoration: none;
        color: white;
        padding: 15px 120px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        margin-top: 30px;
        display: block;
        width: 60%;
    }
    .second-step input, textarea {
        display: inline- block;
        font-size: 14px;
        padding: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
    }
    .w170 {
        width: 160px;
    }
    .w350 {
        width: 350px;
    }
    .fourth-step {
        float: left;
        display: block;
        margin-top: 20px;
    }
    .fifth-step {
        float: left;
        display: block;
        margin-top: 20px;
        text-align: left;
        width: 80%;
    }
    .fifth-step p {margin-bottom: 30px;}

      .fifth-step img {
        display: block; margin: 0 auto 30px auto;
    }
    .fourth-step input {
        display: block;
        font-size: 16px;
        padding: 15px;
        width: 350px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        margin-left: 70px;
    }
    .inpts {
        display: block;
        width: 100%;
        float: left;
        margin-left: 70px;
    }
`}
      </style>
      <div className="fomento-logo">
        <img src={fomento} height="90" alt="fomento" />
      </div>

      <div className="eel-title">
        <h1>English Exams Lab</h1>
      </div>

      <div className="actions">
        <div className="image">
          <img src={img} alt="license prices" />
        </div>
        <div className="steps">
          <div className="fifth-step">
            <img src={err} height="100" alt="error" />
            <p>El alumno/a para el que ha intentado comprar una licencia ya existe en nuestro sistema y tiene una licencia válida.</p>
            <p>
              Si tiene cualquier duda, puede contactarnos en el siguiente correo electrónico:{" "}
              <a href="mailto:support@englishexamslab.com">support@englishexamslab.com</a>.
            </p>
            <p>
              <a href="/">¿Quiere volver a intentarlo?</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fomento;
