import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

export default function CheckoutForm({ paymentIntentClientSecret, paymentId, userData }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!paymentIntentClientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js hasn't yet loaded.");
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const urlParams = new URLSearchParams({
      paymentId: paymentId,
      name: userData.name,
      lastName: userData.lastName,
      dni: userData.dni,
      phoneNumber: userData.phoneNumber,
      email: userData.email,
      postalCode: userData.postalCode,
      address: userData.address,
      city: userData.city,
      school: userData.school,
      fullStudentName: userData.fullStudentName,
      studentEmail: userData.studentEmail,
    });

    console.log("URL Params:");
    console.log(urlParams.toString());

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3006/payment-success?" + urlParams.toString(),
        return_url: "https://www.fomentoexamslab.com/payment-success?" + urlParams.toString(),
        // Add the customer details here
        payment_method_data: {
          billing_details: {
            address: {
              city: userData.city,
              country: "ES",
              line1: userData.address,
              postal_code: userData.postalCode,
            },
            name: userData.name + " " + userData.lastName,
            email: userData.email,
            phone: userData.phoneNumber,
          },
        },
      },
    });

    console.log("Error is:", error);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      console.log("Unexpected error is:", error);
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    fields: {
      billingDetails: {
        address: {
          country: "never",
        },
      },
    },
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="btn-comprar">
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pagar"}</span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
